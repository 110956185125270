<template>
  <div class="box box_mm">
    <div class="box_main">
      <div class="search_box">
        <div class="search_item">
          <span style="width: 0.8rem">标题：</span>
          <el-input
            v-model="searchinput"
            placeholder="请输入"
            style="width: 2rem"
          ></el-input>
        </div>
        <div class="search_item">
          <el-button type="primary" @click="search">搜索</el-button>
          <!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
        </div>
      </div>
      <div class="box_cont">
        <div class="btn_list"></div>
        <div style="height: 76vh; box-sizing: border-box">
          <MyTable
            ref="zjlist"
            :tableData="tableData"
            :tableHeader="tableHeader"
            :showbm="true"
            :czwidth="210"
            @getdata="gettable"
            @changswitch="changswitch"
          >
            <template slot="btns" slot-scope="{ scope }">
              <el-button
                @click="showdetail(scope.row)"
                type="primary"
                size="mini"
                >详情</el-button
              >
              <el-button @click="showedit(scope.row)" type="warning" size="mini"
                >修改</el-button
              >
              <el-button @click="showshenhe(scope.row)" type="success" size="mini"
                >复审</el-button
              >
            </template>
          </MyTable>
        </div>
      </div>
    </div>

    <el-dialog
      title="审核"
      :visible.sync="showshenheTF"
      width="40%"
      :before-close="closeyj"
      :destroy-on-close="true"
    >
      <div>
        <el-form ref="info" :model="info" :rules="yjrules" label-width="100px">
          <el-form-item label="意见" prop="fsyj">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入"
              v-model="info.fsyj"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeyj">取 消</el-button>
        <el-button @click="butonguo" type="danger">审核不通过</el-button>
        <el-button type="primary" @click="tongguo">审核通过</el-button>
      </span>
    </el-dialog>

    <detail
      v-if="showtcdetail"
      :id="nowid"
      @closedetail="showtcdetail = false"
    ></detail>
  </div>
</template>

<script>
import detail from "./detail_tc";
export default {
  components: {
    detail,
  },
  data() {
    return {
      searchinput: "",
      tableHeader: [
        {
          prop: "id",
          name: "序",
          width: "50",
        },
        {
          prop: "name",
          name: "标题",
          width: "",
        },
        {
          prop: "sqmytype_name",
          name: "所属类别",
          width: "150",
        },
        {
          prop: "sqmychannel_name",
          name: "来源渠道",
          width: "150",
        },
        {
          prop: "user_name",
          name: "发起人",
          width: "120",
        },
        {
          prop: "state",
          name: "状态",
          width: "120",
          type: "sqmy_type",
        },
        {
          prop: "createtime",
          name: "创建时间",
          width: "150",
        },
      ],
      tableData: [],
      showtcdetail: false,
      nowid: "",

      showshenheTF: false,
      info: {
        id: "",
        fsyj: "",
      },
      yjrules: {
        fsyj: [
          {
            required: true,
            message: "请输入",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.gettable();
  },
  methods: {
    showshenhe(row) {
      this.showshenheTF = true;
      this.info.id = row.id;
    },
    butonguo() {
      this.$refs.info.validate((valid) => {
        if (valid) {
          this.$post({
            url: "/api/sqmy/fsbohui",
            params: {
              ...this.info,
            },
          }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.closeyj();
            this.gettable();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    tongguo() {
      this.$refs.info.validate((valid) => {
        if (valid) {
          this.$post({
            url: "/api/sqmy/fstguo",
            params: {
              ...this.info,
            },
          }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.closeyj();
            this.gettable();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeyj() {
      this.showshenheTF = false;
    },
    showedit(row) {
      this.$router.push({
        path: "/system/people_opinions_list_add",
        query: { id: row.id },
      });
    },
    showdetail(row) {
      this.nowid = row.id;
      this.showtcdetail = true;
    },
    postdata(row) {},
    showeidt(row) {
      this.$router.push("/system/get_file/edit?id=" + row.id);
    },
    changswitch(row) {
      this.$post({
        url: "/api/room/edit",
        params: row,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.gettable();
      });
    },
    search() {
      this.$refs.zjlist.inputpage = 1;
      this.gettable();
    },
    gettable() {
      this.$post({
        url: "/api/sqmy/quanindex",
        params: {
          name: this.searchinput,
          p: this.$refs.zjlist.inputpage,
          size: this.$refs.zjlist.pagesize,
          state: 2,
        },
      }).then((res) => {
        this.tableData = res.list;
        this.$refs.zjlist.total = res.count;
      });
    },
    addnew() {
      this.$router.push("/system/get_file/add");
    },
    showdate(row) {
      this.$refs.myroomdate.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "list.scss";

::v-deep .el-dialog__footer span {
  display: flex !important;
  justify-content: flex-end;
  width: 100%;
}
</style>
